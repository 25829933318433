<template>
<div>
  <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">

      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('PurchaseOrderPrinciple')}`" class="mb-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <div style="display: flex; align-items: center; justify-content: center;">
            <vs-button color="primary" class="mr-3" icon-pack="feather" icon="icon-edit" @click="edit">
              {{$i18n.t('Edit')}}
            </vs-button>
            <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'purchase-to-principle' })">
              {{$i18n.t('Back')}}
            </vs-button>
          </div>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="font-bold mb-2">PO Date</h6>
              <p style="line-height: 1.5;">{{item.tanggal_display}}</p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="font-bold mb-2">PO Number</h6>
              <p style="line-height: 1.5;">{{item.po_number}}</p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="font-bold mb-2">Project Name</h6>
              <p style="line-height: 1.5;">{{item.project_name}}</p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="font-bold mb-2">Customer Name</h6>
              <p style="line-height: 1.5;">{{item.customer ? item.customer.name : '-'}}</p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="font-bold mb-2">Principle Name</h6>
              <p style="line-height: 1.5;">{{item.principle_name}}</p>
            </div>
          </div>
        </div>


      </vx-card>

      <vx-card :title="$i18n.t('Spesification')" style="margin-bottom: 60px;">

        <vs-table :data="item.specifications">

          <template slot="thead">
            <vs-th>Detail Project</vs-th>
            <vs-th class="text-center">Qty</vs-th>
            <vs-th class="text-right">Price</vs-th>
            <vs-th class="text-right">Total</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>

              <vs-td :data="data[indextr].qty" class="text-center">
                {{ data[indextr].qty }}
              </vs-td>

              <vs-td :data="data[indextr].price" class="text-right">
                <span style="float: left;">{{ currencies[data[indextr].currency-1].text }}</span>
                <span style="letter-spacing: 1.2px;">{{ formatNumber(data[indextr].price) }}</span>
              </vs-td>

              <vs-td :data="data[indextr].total" class="text-right">
                <span style="float: left;">{{ currencies[data[indextr].currency-1].text }}</span>
                <span style="letter-spacing: 1.2px;">{{ formatNumber(data[indextr].price * data[indextr].qty) }}</span>
              </vs-td>

            </vs-tr>
            <vs-tr style="background: #8080;">
              <vs-td colspan="3" class="text-right" style="font-weight: bold;">Total</vs-td>
              <vs-td style="font-weight: bold; letter-spacing: 1.2px;" class="text-right">
                {{ total(data) }}
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>

      </vx-card>

    </div>
  </div>
</div>
</template>

<script>
import axios from '@/axios';
export default {
  name: "IncomingLetterDetail",
  data: () => ({
    item: {},
    currencies: [
      {
        text: 'USD',
        value: '1'
      },
      {
        text: 'EUR',
        value: '2'
      },
      {
        text: 'IDR',
        value: '3'
      }
    ],
  }),
  computed: {
    cells () {
      return [
        {
          header: this.$i18n.t('PODate'),
          field: 'date'
        },
        {
          header: this.$i18n.t('PONumber'),
          field: 'po_number'
        },
        {
          header: this.$i18n.t('PrincipleName'),
          field: 'principle_name'
        },
        {
          header: this.$i18n.t('CustomerName'),
          field: 'customer.name'
        }
      ]
    }
  },
  methods: {
    edit () {
      this.$router.push({ name: 'purchase-to-principle-edit', params: { id: this.$route.params.id } });
    },
    total (data) {
      let currencies = this.currencies
        .map(currency => {
          currency.total = data.filter(item => item.currency == currency.value).map(item => parseInt(item.price) * parseInt(item.qty));
          return currency;
        })
        .filter(currency => currency.total.length > 0)
        .map(currency => {
          currency.total = currency.total.reduce((total, item) => total += item);
          return `${currency.text} ${this.formatNumber(currency.total)}`;
        });

      return currencies.join(' + ');
    },
    formatNumber (value) {
      let val = (value/1).toFixed(0).replace('.', ',');
      let result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return result;
    },
    format (value) {

    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    axios.get('/purchase-principles/' + this.id).then(resp => {
      this.item = resp.data.values;
      console.log(this.item);
    });
  }
}
</script>

<style scoped>

</style>
